import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import { createHead } from '@vueuse/head'
const head = createHead()

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, fas } from '@fortawesome/free-solid-svg-icons'

import { fab } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faUserSecret, fas, fab)

import '../node_modules/bootswatch/dist/sketchy/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

createApp(App)
  .use(router)
  .use(head)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
