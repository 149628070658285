<template>
  <HeaderView />
  <div class="container text-center">
    <img
      alt="Kevin Jam"
      title="Kevin Jam"
      src="../assets/kevinjam-small.jpg"
      class="headimage rounded-circle mt-3"
    />
    <KevinJam msg="Kevin Jam's Work Samples" />

    <div v-if="projects" class="row flex-row d-flex">
      <template v-for="item in projects" :key="item.orderby">
        <div class="col-md-6 mb-3">
          <div class="card mb-3 h-100">
            <div class="card-header">
              <div class="row">
                <div class="col-11">
                  <h3>
                    {{ item.projectname }}
                    <h6 class="text-secondary">
                      <i class="fa-regular fa-user mx-2" title="ownership"></i
                      >{{ item.ownership }}
                    </h6>
                  </h3>
                </div>
                <div class="col-1">
                  <IconsView :iconsText="item.tags" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <template v-if="item.url">
                    <vue-load-image>
                      <a :href="item.url" target="_blank">
                        <img
                          v-bind:src="
                            `https://image.thum.io/get/width/300/crop/820/` +
                            item.url
                          "
                          v-bind:alt="item.projectname"
                          v-bind:title="item.projectname"
                          class="mx-y img-thumbnail"
                          loading="lazy"
                        />
                      </a>
                    </vue-load-image>
                  </template>
                  <template v-else>
                    <img
                      src="../assets/commingsoon.png"
                      class="mx-y img-thumbnail"
                      title="Coming Soon"
                    />
                  </template>
                </div>
                <div class="col-md-7 text-start">
                  {{ item.comments }}
                </div>
              </div>
              <p class="card-text mt-2">
                <template v-if="item.tags">
                  <div class="text-info">
                    <i class="fa-solid fa-tags fa-lg mx-2" title="tags"></i>
                    {{ item.tags }}
                  </div>
                </template>
              </p>
              <div>
                <template v-if="item.url">
                  <i
                    class="fa-solid fa-arrow-up-right-from-square fa-lg mx-2"
                    title="link"
                  ></i>
                  <a
                    :href="item.url"
                    target="_blank"
                    class="card-link text-success"
                    >{{ item.url }}</a
                  >
                </template>
              </div>
              <ProgressBar :completionPercent="item.completion" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>No Projects To Show</div>
  </div>
  <FooterView :theyear="theyear" />
</template>

<script>
import axios from 'axios'
const config = {
  headers: {
    Authorization: `Bearer 3|58N1JdZh8W17L0C1QVoSif5rdn3JELHMKYpI0gar8ac9a437`
  },
  params: {
    'filter[showhide]': '1'
  }
}

//for lazy loading we need defineAsyncComponent
import { defineAsyncComponent } from 'vue'

//we use axios for loading a json api content instad of calling a file
//import jsonData from '../assets/kevinjamcontent.json'

// @ is an alias to /src
import KevinJam from '@/components/Topofthesite.vue'
import HeaderView from '@/components/HeaderView.vue'
//trying lazy loading
const FooterView = defineAsyncComponent(() =>
  import('@/components/FooterView.vue')
)

import ProgressBar from '@/components/ProgressBar.vue'
import IconsView from '@/components/IconsView.vue'

export default {
  name: 'HomeView',
  components: {
    KevinJam,
    HeaderView,
    FooterView,
    ProgressBar,
    IconsView
  },

  data() {
    return {
      currentPage: 1,
      max: 100,
      projects: [],
      theyear: new Date().getFullYear()
    }
  },
  async mounted() {
    let result = await axios.get('https://api.kevinjam.au/api/projects', config)

    this.projects = result.data.data
  }
}
</script>
