<template>
  <font-awesome-icon
    v-bind:icon="`` + findIcons(iconsText) + ``"
    title="Status"
    size="lg"
  />
</template>

<script>
export default {
  name: 'IconsDiv',
  props: ['iconsText'],
  methods: {
    findIcons(iconsText) {
      let iconSet = ''
      if (iconsText.includes('Complete')) {
        iconSet = 'check'
      } else if (iconsText.includes('Ongoing')) {
        iconSet = 'gear'
      } else if (iconsText.includes('Old')) {
        iconSet = 'clock'
      } else {
        iconSet = 'person-digging'
      }
      return iconSet
    }
  }
}
</script>
