<template>
  <router-view />
</template>

<script>
import { computed, reactive, defineAsyncComponent } from 'vue'
//dynamic headers
import { useHead } from '@vueuse/head'
export default {
  setup() {
    const siteData = reactive({
      title: `Kevin Jam Sample Works`,
      description: `Exploring Kevin Jam's Real and Sample Projects with Cutting-Edge Technologies`
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        }
      ]
    })
  }
}
</script>

<style lang="scss">
.headimage {
  width: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 3px 7px 15px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
}
</style>
