<template>
  <div class="subtitle">
    <h1>{{ msg }}</h1>
    <p>
      Exploring Kevin Jam's Real and Sample Projects with Cutting-Edge
      Technologies
    </p>
  </div>
</template>

<script>
export default {
  name: 'TopofSite',
  props: {
    msg: String
  }
}
</script>
