<template>
  <h6 class="text-start mx-3">
    Completion <i class="fa-solid fa-pen-ruler mx-2"></i
    ><span> {{ completionPercent }}%</span>
    <div class="progress mx-3 mb-3">
      <div
        v-bind:class="
          `progress-bar ` +
          claculatebgcolor(completionPercent) +
          ` progress-bar-striped progress-bar-animated`
        "
        role="progressbar"
        v-bind:style="`width:` + completionPercent + `%`"
        aria-valuenow="35"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </h6>
</template>

<script>
export default {
  name: 'ProgressBar',
  thebarclass: 'bg-success',
  props: ['completionPercent'],
  methods: {
    claculatebgcolor(completionPercent) {
      let bgclass = ''
      // 3 conditions based on completionPercen
      if (completionPercent > 70) {
        console.log(completionPercent)
        bgclass = 'bg-success'
      } else if (completionPercent <= 70 && completionPercent > 40) {
        bgclass = 'bg-warning'
      } else {
        bgclass = 'bg-danger'
      }
      return bgclass
    }
  }
}
</script>
